import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import WirUeberUnsSubmenu from "../../components/wirueberuns/wirueberuns_submenu"
import WirUeberUnsContentHeader from "../../components/wirueberuns/wirueberuns_contentHeader"
import TermineMargin from "../../components/termine-margin"

import { graphql } from "gatsby"

export const query = graphql`
  {
    allSanitySiteSettings {
      nodes {
        motto
      }
    }
  }
`

const glanzlichter = ({data}) => (
  <Layout>
    <SEO title="Glanzlichter"/>

    <WirUeberUnsContentHeader headline="UNSERE GLANZLICHTER" motto={data.allSanitySiteSettings.nodes[0].motto}/>

    <section id="content_2_schule">
      <WirUeberUnsSubmenu/>

      <article id="con_2_rechts">

        <h3 className="small_sub"><span style={{ color: "#007c34" }}>GLANZLICHTER</span></h3>

      </article>


      <TermineMargin/>
    </section>
  </Layout>
)

export default glanzlichter
